import {
    Box,
    Button,
    Center,
    chakra,
    Flex,
    Heading,
    SimpleGrid,
    Stat,
    StatLabel,
    StatNumber,
    Text,
    useColorModeValue,
    VStack,
} from '@chakra-ui/react';

import { useEffect, useState } from "react";
import Web3Service from "./../core/web3.service";
import { FaMoneyBill } from 'react-icons/fa';
import StatsCard from './StatsCard';
import { tap, merge } from "rxjs";

interface ClaimViewProps {
}

const ClaimView: React.FC<ClaimViewProps> = (props) => {
    const web3Service = Web3Service.shared();
    const [royalties, setRoyalties] = useState<string>();

    useEffect(() => {
        const royalties$ = web3Service.royalties$.pipe(
            tap((royalties) => {
                setRoyalties(royalties)
            })
        );

        const subscription = merge(
            royalties$
        ).subscribe();

        return () => {
            subscription.unsubscribe();
        };
    })

    return (
        <>
            <Box maxW="7xl" mx={'auto'} pt={5} px={{ base: 2, sm: 12, md: 16 }} textColor={'white'}>
                <Heading pt={16} pb={4} fontWeight={"400"} fontSize={{ base: "xl", sm: "2xl", md: "4xl"}} textAlign={"center"}>Royalties</Heading>
                <Center>
                    <Box>
                        <VStack>
                            <Center maxWidth={500} textAlign={'center'} fontSize={{ base: "lg", sm: "xl", md: "2xl" }}>
                                <VStack>
                                    <Text fontWeight={"600"}>Earn royalties from:</Text>
                                    <Text>50% of Tough Lions & Hype Hogs secondary sales</Text>
                                    <Text>20% of Lottery volume</Text>
                                </VStack>
                            </Center>

                            <StatsCard
                                title={'Amount available to claim'}
                                stat={(royalties ?? "-") + " CRO"}
                                icon={<FaMoneyBill size={'3em'} />}
                            />
                            <Center pt={4}><Button border={"2px solid"} backgroundColor={"rgba(0, 0, 0, 0.5)"} colorScheme={"gray"} onClick={web3Service.claimRoyalties}>Claim</Button></Center>
                        </VStack>
                    </Box>
                </Center>
            </Box>
        </>
    );
};

export default ClaimView;