import { tap, merge } from "rxjs";
import {
  ChakraProvider,
  extendTheme,
  Progress,
  useToast,
  Button,
} from "@chakra-ui/react";

import "@fontsource/koulen/400.css";
import { Global, css } from '@emotion/react';
import { useEffect, useState } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

import Web3Service from "./core/web3.service";

import LotteryView from "./components/LotteryView";
import { LotteryGame } from "./core/LotteryGame";
import ClaimView from "./components/ClaimView";
import HeaderNavigation from "./components/HeaderNavigation";

function App(): any {
  const toast = useToast();
  const web3Service = Web3Service.shared();

  const [isLoading, setIsLoading] = useState(false);
  const [connected, setConnected] = useState(false);
  const [account, setAccount] = useState<string>();
  const [lotteryGame, setLotteryGame] = useState<LotteryGame | undefined>(undefined);

  useEffect(() => {
    web3Service.connectToCachedProvider();

    const connected$ = web3Service.connected$.pipe(
      tap((connected) => {
        setConnected(connected);
      })
    );

    const account$ = web3Service.account$.pipe(
      tap((account) => {
        setAccount(account);
      })
    );

    const showToast$ = web3Service.showToast$.pipe(
      tap((info: { title: string }) => {
        toast({
          description: info.title,
          status: "success",
        });
      })
    );

    const errors$ = web3Service.errors$.pipe(
      tap((error) => {
        toast({
          description: error,
          status: "error",
        });
      })
    );

    const isLoading$ = web3Service.isLoading$.pipe(
      tap((isLoading) => {
        setIsLoading(isLoading);
      })
    );

    const lotteryGame$ = web3Service.lotteryGame$.pipe(
      tap((lotteryGame) => {
        setLotteryGame(lotteryGame)
      })
    );

    const subscription = merge(
      connected$,
      showToast$,
      errors$,
      account$,
      isLoading$,
      lotteryGame$
    ).subscribe();

    return () => {
      subscription.unsubscribe();
    };
  });

  const config = {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  }

  const theme = extendTheme({
    fonts: {
      heading: `'Koulen', cursive`,
    }, config, styles: {
      global: () => ({
        // body: {
        //   bg: "transparent",
        // },
      }),
    },
  })

  const home = Home(connected, web3Service.isCorrectChainId(), web3Service.toggleConnect, web3Service.switchNetwork)

  return (
    <ChakraProvider theme={theme}>
      <Global styles={css`
      html body {
        background: url(../images/background-image.webp) no-repeat center center fixed; 
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        font-family: Koulen,cursive;
      }
    `} />

      <Progress size="xs" isIndeterminate opacity={isLoading ? 100 : 0} />

      <HeaderNavigation
        isConnected={connected}
        isCorrectChainId={web3Service.isCorrectChainId()}
        toggleConnect={web3Service.toggleConnect}
        switchNetwork={web3Service.switchNetwork}
      />

      <Router>
        <Routes>
          <Route path='/royalties' element={<ClaimView />} />
          <Route path='/lottery' element={<LotteryView playLottery={web3Service.playLottery} lotteryGame={lotteryGame} />} />
          <Route path='/' element={home} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

function Home(
  isConnected: boolean,
  isCorrectChainId: boolean,
  toggleConnect: () => void,
  switchNetwork: () => void
) {
  let switchNetworkButton;

  if (!isCorrectChainId && isConnected) {
    switchNetworkButton = (
      <Button color={"orange.500"} onClick={switchNetwork}>
        {"Switch Network"}
      </Button>
    );
  }

  return (
    <>
      {/* <Center m={4}>
        <Stack direction={"row"} spacing={7}>
          <Button backgroundColor={"rgba(255, 255, 255, 0.4)"} fontSize={'2xl'} onClick={toggleConnect}>
            {isConnected ? "Disconnect" : "Connect Wallet"}
          </Button>

          {switchNetworkButton}
        </Stack>
      </Center> */}
    </>
  );
}

export default App;
