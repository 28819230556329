import {
  Box,
  Flex,
  Image,
  Button,
  useColorModeValue,
  Stack,
  Center,
  useColorMode,
  Heading,
  IconButton,
  LinkOverlay,
  Link,
  HStack,
} from "@chakra-ui/react";

import logo_image from "../images/logo.webp"

interface HeaderNavigationProps {
  isConnected: boolean;
  isCorrectChainId: boolean;
  toggleConnect: () => void;
  switchNetwork: () => void;
}

const HeaderNavigation: React.FC<HeaderNavigationProps> = (props) => {

  let switchNetworkButton;

  if (!props.isCorrectChainId && props.isConnected) {
    switchNetworkButton = (
      <Button color={"orange.500"} backgroundColor={"rgba(0, 0, 0, 0.7)"} fontSize={{ base: "md", sm: "lg", md: "2xl" }} onClick={props.switchNetwork}>
        {"Switch Network"}
      </Button>
    );
  }

  const handleHomeClick = () => {
    window.open("https://zap7art.club/toughlions", "_self");
  }

  return (
    <>
      <Flex mx={4} alignItems={"center"} justifyContent={"space-between"} textColor={'white'}>
        <Center>
          <Link href="https://zap7art.club/toughlions" style={{ textDecoration: 'none' }}>
            <HStack>
              <Heading fontSize={{ base: 18, sm: 24, md: 46 }} fontWeight={300}>TOUGH LIONS</Heading>
              <Image
                h={100}
                w={100}
                ml={4}
                objectFit={"contain"}
                src={logo_image}
                alt="tough-lions-logo"
              />
            </HStack>
          </Link>
        </Center>
        <Flex alignItems={"center"}>
          <Stack direction={"row"} spacing={{ base: 2, sm: 4, md: 6 }}>
            <Button backgroundColor={"rgba(255, 255, 255, 0.4)"} fontSize={{ base: "md", sm: "lg", md: "2xl" }} onClick={handleHomeClick}>
              Home
            </Button>

            <Button backgroundColor={"rgba(255, 255, 255, 0.4)"} fontSize={{ base: "md", sm: "lg", md: "2xl" }} onClick={props.toggleConnect}>
              {props.isConnected ? "Disconnect" : "Connect Wallet"}
            </Button>

            {switchNetworkButton}
          </Stack>
        </Flex>
      </Flex>
    </>
  );
};

export default HeaderNavigation;
