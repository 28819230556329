import {
    Box,
    Flex,
    Stat,
    StatLabel,
    StatNumber,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

interface StatsCardProps {
    title: string;
    stat: string;
    icon: ReactNode;
}
function StatsCard(props: StatsCardProps) {
    const { title, stat, icon } = props;
    return (
        <Stat
            px={{ base: 2, md: 4 }}
            py={'4'}
            shadow={'xl'}
            borderColor={'white'}
            backgroundColor={'rgba(0, 0, 0, 0.7)'}
            textColor={'white'}
            rounded={'lg'}>
            <Flex justifyContent={'space-between'}>
                <Box px={{ base: 2, md: 2 }}>
                    <StatLabel fontWeight={'medium'}>
                        {title}
                    </StatLabel>
                    <StatNumber fontSize={{ base: "md", sm: "lg", md: "2xl"}} fontWeight={'medium'}>
                        {stat}
                    </StatNumber>
                </Box>
            </Flex>
        </Stat>
    );
}

export default StatsCard;