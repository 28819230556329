import { ethers } from "ethers";
import Web3Modal from "web3modal";
import { Subject, BehaviorSubject } from 'rxjs'
import { ROYALTIES_ABI, ROYALTIES_ADDRESS } from ".././config"
import { LOTTERY_ABI, LOTTERY_ADDRESS } from ".././config_lottery"
import { LotteryGame } from "./LotteryGame";

class ChainModel {
    id: number
    name: string
    currencyName: string
    decimals: number
    symbol: string
    rpcUrls: string[]

    constructor(id: number,
        name: string,
        currencyName: string,
        decimals: number,
        symbol: string,
        rpcUrls: string[]) {
        this.id = id
        this.name = name
        this.currencyName = currencyName
        this.decimals = decimals
        this.symbol = symbol
        this.rpcUrls = rpcUrls
    }
}

export default class Web3Service {
    static instance: Web3Service

    static shared() {
        if (Web3Service.instance) {
            return Web3Service.instance
        } else {
            Web3Service.instance = new Web3Service()
            return Web3Service.instance
        }
    }

    // Private
    private _connected$ = new BehaviorSubject<boolean>(false)
    private _isLoading$ = new BehaviorSubject<boolean>(false)
    private _account$ = new BehaviorSubject<string | undefined>(undefined)
    private _showToast$ = new Subject<{ title: string }>()
    private _errors$ = new Subject<string>()
    private _lotteryGame$ = new BehaviorSubject<LotteryGame | undefined>(undefined)
    private _royalties$ = new BehaviorSubject<string>("-")

    // Public
    public readonly connected$ = this._connected$.asObservable()
    public readonly account$ = this._account$.asObservable()
    public readonly showToast$ = this._showToast$.asObservable()
    public readonly errors$ = this._errors$.asObservable()
    public readonly isLoading$ = this._isLoading$.asObservable()
    public readonly lotteryGame$ = this._lotteryGame$.asObservable()
    public readonly royalties$ = this._royalties$.asObservable()

    // Logic
    private web3Modal: Web3Modal
    private provider?: ethers.providers.Web3Provider
    private didConnectOnLoad: boolean = false

    private _chain = new ChainModel(
        25,
        "Cronos",
        "Crypto.org Coin",
        18,
        "CRO",
        ['https://evm.cronos.org']

        // 338,
        // "Test Cronos",
        // "Test Crypto.org Coin",
        // 18,
        // "tCRO",
        // ['https://cronos-testnet-3.crypto.org:8545/']

        // 31337,
        // "Localhost Hardhat",
        // "Test Ethereum",
        // 18,
        // "ETH",
        // ['http://127.0.0.1:8545']
    )

    constructor() {
        const providerOptions = {}

        this.web3Modal = new Web3Modal({
            cacheProvider: true,
            providerOptions
        })
    }

    isCorrectChainId = () => {
        if (window.ethereum) return window.ethereum.networkVersion == this._chain.id
        return true
    }

    connectToCachedProvider = async () => {
        if (this.didConnectOnLoad || !this.web3Modal.cachedProvider) return

        this._isLoading$.next(true)
        this.didConnectOnLoad = true
        this.web3Modal.connectTo(this.web3Modal.cachedProvider)
            .then(provider => {
                this.walletConnected(provider, false)
                this._isLoading$.next(false)
            })
            .catch(error => {
                this._isLoading$.next(false)
            })
    }

    toggleConnect = async () => {
        if (this._connected$.value) {
            this.disconnect()
        } else {
            this.connectToWallet(true)
        }
    }

    switchNetwork = async () => {
        if (this.isCorrectChainId()) return

        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: ethers.utils.hexValue(this._chain.id) }]
            })
        } catch (err: any) {
            if (err.code == 4902) {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [{
                        chainName: this._chain.name,
                        chainId: ethers.utils.hexValue(this._chain.id),
                        nativeCurrency: { name: this._chain.currencyName, decimals: this._chain.decimals, symbol: this._chain.symbol },
                        rpcUrls: this._chain.rpcUrls
                    }]
                })
            } else if (err.code == 4901) {
                return
            } else {
                this._errors$.next('There was a problem adding ' + this._chain.name + ' network to MetaMask')
            }
        }
    }

    private walletConnected(provider: any, manual: boolean) {
        this.provider = new ethers.providers.Web3Provider(provider)
        this._connected$.next(true)
        if (manual) {
            this._showToast$.next({ title: "Wallet conntected" })
        }
        this.getAccount()

        this.observeWalletChanges()
        this.subscribeToEvents()
    }

    private observeWalletChanges() {
        this.removeListeners()

        const provider = new ethers.providers.Web3Provider(window.ethereum, "any")

        provider.on("network", (newNetwork, oldNetwork) => {
            if (oldNetwork) {
                window.location.reload()
            }
        })

        window.ethereum.on("accountsChanged", (accounts: string[]) => {
            if (accounts[0] && accounts[0] != this._account$.value) {
                this.getAccount()
            }
        })
    }

    private removeListeners() {
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any")
        provider.removeAllListeners()
    }

    private connectToWallet = async (manual: boolean) => {
        this.web3Modal.clearCachedProvider()

        this._isLoading$.next(true)
        this.web3Modal.connect()
            .then(provider => {
                this._isLoading$.next(false)
                this.didConnectOnLoad = true
                this.walletConnected(provider, manual)

            })
            .catch(error => {
                this._isLoading$.next(false)
            })
    }

    private disconnect = async () => {
        this.web3Modal.clearCachedProvider()
        this._connected$.next(false)
        this._isLoading$.next(false)
        this._account$.next(undefined)
    }

    private getAccount = async () => {
        if (!this.provider) return

        this._isLoading$.next(true)

        this.provider.send("eth_requestAccounts", []).then(accounts => accounts[0])
            .then(account => {
                this._isLoading$.next(false)
                this._account$.next(account)
                this.fetchLotteryStats()
                this.fetchRoyalties()
            })
            .catch(error => {
                this._isLoading$.next(false)
                this._errors$.next("Failed to connect")
            })
    }

    subscribeToEvents() {
        if (!this.provider) return

        // Lottery
        const contract = new ethers.Contract(LOTTERY_ADDRESS, LOTTERY_ABI, this.provider)

        contract.on("PlayerJoined", () => {
            this.fetchLotteryStats()
            this.fetchRoyalties()
        })

        contract.on("PlayerWon", () => {
            this.fetchLotteryStats()
            this.fetchRoyalties()
        })
    }

    // Lottery
    fetchLotteryStats = async () => {
        if (!this.provider || !this._account$.value) return
        this._isLoading$.next(true)

        const contract = new ethers.Contract(LOTTERY_ADDRESS, LOTTERY_ABI, this.provider)

        const signer = this.provider.getSigner()
        const contractSigned = new ethers.Contract(LOTTERY_ADDRESS, LOTTERY_ABI, signer)

        var players: number = 0
        var maxPlayers: number = 0
        var pot: string = "-"
        var volume: string = "-"
        var ticketPrice: string = "-"
        var errorCount = 0

        try {
            players = await contract.getPlayersCount()
        } catch {
            errorCount += 1
        }

        try {
            maxPlayers = await contract.playerLimit()
        } catch {
            errorCount += 1
        }

        try {
            const value = ethers.utils.formatEther(await contract.currentPot())
            pot = Number(value).toFixed(2)
        } catch {
            errorCount += 1
        }

        try {
            const value = ethers.utils.formatEther(await contract.totalVolume())
            volume = Number(value).toFixed(2)
        } catch {
            errorCount += 1
        }

        try {
            const value = ethers.utils.formatEther(await contractSigned.ticketPrice())
            ticketPrice = Number(value).toFixed(2)
        } catch {
            errorCount += 1
        }

        this._isLoading$.next(false)
        this._lotteryGame$.next(
            { players, maxPlayers, pot, volume, ticketPrice }
        )
    }

    fetchRoyalties = async () => {
        if (!this.provider || !this._account$.value) return
        this._isLoading$.next(true)

        const signer = this.provider.getSigner()
        const contractSigned = new ethers.Contract(ROYALTIES_ADDRESS, ROYALTIES_ABI, signer)

        var royalties: string = "-"

        try {
            const value = ethers.utils.formatEther(await contractSigned.getRoyalties())
            royalties = Number(value).toFixed(2)
        } catch(error) {
        }

        this._isLoading$.next(false)
        this._royalties$.next(royalties)
    }

    playLottery = async () => {
        if (!this.provider || !this._account$.value) {
            this.connectToWallet(true);
            return
        }
        this._isLoading$.next(true)

        const signer = this.provider.getSigner()
        const contract = new ethers.Contract(LOTTERY_ADDRESS, LOTTERY_ABI, signer)

        try {
            const price = await contract.ticketPrice()
            const tx = await contract.playLottery({ value: price })
            await tx.wait()

            this._showToast$.next({ title: "You have entered lottery!" })
        } catch (error) {
            this._errors$.next("Something went wrong")
        } finally {
            this._isLoading$.next(false)
        }
    }

    claimRoyalties = async () => {
        if (!this.provider || !this._account$.value) {
            this.connectToWallet(true);
            return
        }
        this._isLoading$.next(true)

        const signer = this.provider.getSigner()
        const contract = new ethers.Contract(ROYALTIES_ADDRESS, ROYALTIES_ABI, signer)

        try {
            const tx = await contract.claimRoyalties()
            await tx.wait()

            this._showToast$.next({ title: "You have claimed royalties!" })
        } catch (error) {
            this._errors$.next("Something went wrong")
        } finally {
            this._isLoading$.next(false)
            this.fetchRoyalties()
        }
    }
}