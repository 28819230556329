import {
    Box,
    Button,
    Center,
    Heading,
    SimpleGrid,
    Text,
} from '@chakra-ui/react';

import { BsPerson } from 'react-icons/bs';
import { BsCreditCard } from 'react-icons/bs';
import { BsWallet } from 'react-icons/bs';
import { LotteryGame } from '../core/LotteryGame';
import StatsCard from './StatsCard';

interface LotteryViewProps {
    playLottery: () => void
    lotteryGame: LotteryGame | undefined
}

const LotteryView: React.FC<LotteryViewProps> = (props) => {
    const { playLottery, lotteryGame } = props;

    return (
        <>
            <Box maxW="2xl" mx={'auto'} pt={5} px={{ base: 2, sm: 12, md: 17 }} textColor={'white'}>
                <Heading pt={16} pb={4} fontWeight={200} fontSize={{ base: "xl", sm: "2xl", md: "4xl"}} textAlign={"center"}>Lottery</Heading>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={{ base: 5, lg: 8 }}>
                    <StatsCard
                        title={'Players'}
                        stat={(lotteryGame?.players ?? "-") + "/" + (lotteryGame?.maxPlayers ?? "-")}
                        icon={<BsPerson size={'3em'} />}
                    />
                    <StatsCard
                        title={'Pot'}
                        stat={(lotteryGame?.pot ?? "-") + ' CRO'}
                        icon={<BsCreditCard size={'3em'} />}
                    />
                    <StatsCard
                        title={'Ticket Price'}
                        stat={(lotteryGame?.ticketPrice ?? "-") + ' CRO'}
                        icon={<BsWallet size={'3em'} />}
                    />
                    <StatsCard
                        title={'Total Volume'}
                        stat={(lotteryGame?.volume ?? "-") + ' CRO'}
                        icon={<BsWallet size={'3em'} />}
                    />
                </SimpleGrid>
            </Box>
            <Center textColor={'white'}>
                <Box px={16} pt={8} mx={'auto'} maxWidth={648}>
                    <Text textAlign={'center'} fontSize={{ base: "sm", sm: "md", md: "2xl"}}>Are you a real Tough Lion? What are you waiting for then?</Text>
                    <Center pt={4}><Button border={"2px solid"} backgroundColor={"rgba(0, 0, 0, 0.5)"} colorScheme={"gray"} onClick={playLottery}>Join Lottery</Button></Center>
                </Box>
            </Center>
        </>
    );
};

export default LotteryView;